import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';
import styled      from '@emotion/styled';

import Link              from '@interness/web-core/src/components/elements/Link/Link';
import CallToAction      from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Carousel          from '@interness/web-core/src/components/media/Carousel/Carousel';
import Separator         from '@interness/web-core/src/components/structure/Separator/Separator';
import Button            from '@interness/web-core/src/components/elements/Button/Button';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';

import Services from '../components/Services';

const Infobox = styled.div`
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 20px 30px;
  color: ${props => props.theme.brand_color};
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  p {
    margin: auto;
    max-width: 640px;
  }
`;

const Attentionbox = styled.div`
  border: 1px solid lightgray;
  border-radius: 2px;
  font-size: 1.2rem;
  padding: 20px 30px;
  text-align: center;
  font-weight: bold;
  p {
    margin: auto;
    max-width: 640px;
  }
  .int-attention {
    color: red;
  }
`;

function IndexPage(props) {
  return (
    <>
      <Carousel>
        {props.data.headerImages.images.map(image => (
          <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}/>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <Heading subtitle={'Medicus curat, natura sanat'}>Tierheilpraxis Christina Sattler</Heading>
        <section>
          <p>...bedeutet sinngemäß "Der Arzt behandelt, die Natur heilt“</p>
          <p>Im antiken hippokratischen Verständnis wurde die Natur als Lebenskraft und als Heilkraft aufgefasst. Als
            Naturheilverfahren werden die Maßnahmen bezeichnet, mit denen die Selbstheilungskräfte des Körpers aktiviert
            oder verstärkt werden. Der Körper ist von Natur aus mit vielen Möglichkeiten zur Abwehr von Krankheiten
            ausgestattet.</p>
          <MoreExpander>
            <p>Diese Abwehrmaßnahmen werden unterstützt und angeregt. Die Naturheilkunde unterstützt den "inneren Arzt",
              wie Paracelsus diese Selbstheilungskräfte nannte. Sie strebt an, nicht nur die Symptome einer Erkrankung
              zu
              beseitigen, sondern die zugrunde liegenden Ursachen der Krankheit zu therapieren, soweit dies möglich ist.
              Die Naturheilkunde umfasst Verfahren zur Diagnose, Behandlung und Vorbeugung, die die konventionelle
              Medizin
              ergänzen. Das geschieht durch Konzepte, die in der „Schulmedizin“ üblicherweise nicht vorkommen. Bei den
              alternativen Heilmethoden finden sich Kombinationen aus Elementen der konventionellen Medizin und der
              Naturheilkunde. Die alternativen Heilmethoden sind aber nicht als Ersatz zum Tierarzt zu sehen, sondern
              verstehen sich als Unterstützung. Ziel ist es, das Tier als Einheit von Körper und Seele und als Teil des
              Lebens zu betrachten und aus dieser Position heraus Störfaktoren und Wege zu ihrer Überwindung zu finden.
              Natürliche Gesundheit für Ihr Tier.</p>
          </MoreExpander>
        </section>
        <Spacer/>
        <LiveAnnouncements/>
        <Spacer/>
        <Services type='grid'/>
        <Separator/>
        <Infobox>
          <p>Unser Aufnahmebogen für neue Patienten, am PC ausfüllen, ausdrucken, unterschreiben und mitbringen</p>
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <Button as={Link} primary to={props.data.aufnahmebogen.media[0].file.localFile.publicURL}
                    external>Aufnahmebogen</Button>
          </div>
        </Infobox>
        <Spacer/>
        <Attentionbox>
          <p className='int-attention'>Wichtiger Hinweis!</p>
          <p>Bitte beachten Sie, dass aus organisatorischen Gründen,<br/>
            <span className='int-attention'> ab dem 01.08.2017,</span><br/>
            bei Nichteinhaltung von terminlichen Absprachen<br/>
            bis 24 Stunden VOR dem Termin eine<br/>
            <span className='int-attention'> Ausfallgebühr in Höhe der vorgehaltenen Leistung </span><br/>
            erhoben werden muss.<br/>
            Wir danken für Ihr Verständnis</p>

          <p>Ihr Praxisteam der Tierheilpraxis Christina Sattler</p>
        </Attentionbox>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "home-headers"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    aufnahmebogen: directusMediaCollection(name: {eq: "aufnahmebogen"}) {
      media {
        file {
          localFile {
            name
            publicURL
          }
        }
      }
    }
  }
`;