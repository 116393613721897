import React            from 'react';
import { Flex, Box }    from '@chakra-ui/react'
import ResponsiveIFrame from '@interness/web-core/src/components/structure/iFrame/iFrame';

import { videos } from '../data/content';
import Spacer     from '@interness/web-core/src/components/structure/Spacer/Spacer';

const Videos = () => (
  <div>
    {videos.map(video => (
      <React.Fragment key={video.videoLink}>
        <Flex flexWrap="wrap">
          <Box width={['100%', '50%', '50%', '50%']}>
            <h3>{video.heading}</h3>
            <p>{video.subHeading}</p>
            <p>{video.description}</p>
          </Box>
          <Box width={['100%', '50%', '50%', '50%']}>
            <ResponsiveIFrame src={video.videoLink} title={'YoutubeVideo'} type="youtube"/>
          </Box>
        </Flex>
        <Spacer/>
      </React.Fragment>
    ))}
  </div>
);

export default Videos;