import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Carousel from '@interness/web-core/src/components/media/Carousel/Carousel';
import Videos from '../components/Videos';
export const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "videos-header"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Img = makeShortcode("Img");
const Wrapper = makeShortcode("Wrapper");
const Heading = makeShortcode("Heading");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <hr></hr>
    <Carousel mdxType="Carousel">
      {props.data.headerImages.images.map(image => <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid} mdxType="Img" />)}
    </Carousel>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <Heading subtitle='Tierheilpraxis Christina Sattler' mdxType="Heading">Videos</Heading>
      <hr></hr>
      <Videos mdxType="Videos" />
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
        <CallToAction mdxType="CallToAction" />
      </div>
    </Wrapper>
    <hr></hr>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      