import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Carousel from '@interness/web-core/src/components/media/Carousel/Carousel';
import Services from '../components/Services';
export const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "leistungen-headers"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Img = makeShortcode("Img");
const Wrapper = makeShortcode("Wrapper");
const Heading = makeShortcode("Heading");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <hr></hr>
    <Carousel mdxType="Carousel">
      {props.data.headerImages.images.map(image => <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid} mdxType="Img" />)}
    </Carousel>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <Heading subtitle='Tierheilpraxis Christina Sattler' mdxType="Heading">
    Unsere Praxisleistungen
      </Heading>
      <p>{`Wir möchten unsere Patienten gerne umfassend kennen lernen, und bitten darum, zu den Untersuchungen alle notwendigen Dokumente und Unterlagen bereit zuhalten.`}<br />{`
Unser Hauptaugenmerk liegt bei der Gesundheit Ihres Tieres. Dies geht am Besten wenn wir erfahren, welche Untersuchungsergebnisse und Therapieansätze die bisherigen Therapeuten und Ärzte verfolgt haben. Hierbei interessieren uns vor allem bisherige Erkrankungen, eventuell durchgeführte Operationen, Bluttests und weitere Ergebnisse von Laboruntersuchungen. Nimmt Ihr Tier derzeit bereits Medikamente? Bitte bringen sie die Unterlagen zu den bisherigen Untersuchungen mit. Impfpass und mit welchem Präparat die letzten Wurmkuren (wenn klassisch entwurmt wird) erfolgt sind, sind ebenfalls für uns eine wichtige Informationsquelle.`}</p>
      <hr></hr>
      <Services type='list' mdxType="Services" />
      <div style={{
        textAlign: 'center'
      }}>
        <CallToAction mdxType="CallToAction" />
      </div>
    </Wrapper>
    <hr></hr>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      