import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import { Flex, Box } from '@chakra-ui/react';
import ResponsiveIFrame from '@interness/web-core/src/components/structure/iFrame/iFrame';
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Carousel from '@interness/web-core/src/components/media/Carousel/Carousel';
export const query = graphql`
  query {
    facharbeit: directusMediaCollection(name: {eq: "facharbeit"}) {
      media {
        file {
          localFile {
            name
            publicURL
          }
        }
      }
    }
    headerImages: directusMediaCollection(name: {eq: "praxis-headers"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Img = makeShortcode("Img");
const Wrapper = makeShortcode("Wrapper");
const Heading = makeShortcode("Heading");
const Link = makeShortcode("Link");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Carousel mdxType="Carousel">
      {props.data.headerImages.images.map(image => <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid} mdxType="Img" />)}
    </Carousel>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <Heading subtitle='Was uns einzigartig macht' mdxType="Heading">
    Tierheilpraxis Christina Sattler
      </Heading>
      <p>{`Wir schöpfen aus einem mittlerweile über zehnjährigen Erfahrungsschatz und nehmen uns die notwendige und wichtige Zeit für Sie und Ihr Tier. Unsere sorgfältige Anamnese und die darauf folgende Beratung kann manchmal mehr als eine Stunde ausmachen.`}<br />{`
Wir halten uns stets auf aktuellem Wissensstand und bemühen uns für jeden Patienten um eine tiefgreifende Recherche. Das Wohl Ihres Tieres steht bei uns im Mittelpunkt. Wir erklären Ihnen verständlich und genau was ihrem Tier fehlt und welche Möglichkeiten uns zur Verbesserung der Gesundheit ihres Lieblings zur Verfügung stehen. Während der folgenden Therapie begleiten wir Sie mit Rat und Tat.`}<br />{`
Die Zusammenarbeit mit Tierärzten und Chirurgen ist die Basis für die weitere Behandlung Ihres Tieres. Für einen optimalen Heilungsprozess und die Gesundheit Ihres Tieres, ist diese Kooperation, gerade auch während der Rehamaßnahmen, enorm wichtig.`}</p>
      <hr></hr>
      <h2 {...{
        "id": "unser-praxisvideo"
      }}>{`Unser Praxisvideo`}</h2>
      <ResponsiveIFrame maxHeight={56.25} src='https://player.vimeo.com/video/376359855?dnt=1' title='VimeoVideo' type='vimeo' mdxType="ResponsiveIFrame" />
      <hr></hr>
      <h2 {...{
        "id": "unser-team"
      }}>{`Unser Team`}</h2>
      <TeamMembers mdxType="TeamMembers" />
      <hr></hr>
      <h2 {...{
        "id": "unsere-historie"
      }}>{`Unsere Historie`}</h2>
      <Flex flexWrap='wrap' mdxType="Flex">
        <Box mb={4} p={3} width={['100%', '50%', '50%', '50%']} mdxType="Box">
          <p><strong parentName="p">{`2006`}</strong></p>
          <p>{`Eröffnung der Fahrpraxis für Groß- und Kleintiere.`}</p>
        </Box>
        <Box p={3} width={['100%', '50%', '50%', '50%']} mdxType="Box">
          <p><strong parentName="p">{`2007`}</strong></p>
          <p>{`Durch den VdT geprüfte und zertifizierte Tierheilpraktikerin.`}</p>
          <Link external to={props.data.facharbeit.media[0].file.localFile.publicURL} mdxType="Link">Facharbeit zur Erlangung der VdT</Link>
        </Box>
      </Flex>
      <Flex mdxType="Flex">
        <Box p={3} width={['100%', '50%', '50%', '50%']} mdxType="Box">
          <p><strong parentName="p">{`2008`}</strong></p>
          <p>{`Niederlassung in eigenen Praxisräumen im Herzen der Heimatstadt Schwelm.`}</p>
          <p>{`Weiterführendes Studium der Akupunktur für Tiere.`}</p>
        </Box>
        <Box p={3} width={['100%', '50%', '50%', '50%']} mdxType="Box">
          <p><strong parentName="p">{`2010`}</strong></p>
          <p>{`Anschaffung eines "Water Walkers"`}</p>
        </Box>
      </Flex>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
        <CallToAction mdxType="CallToAction" />
      </div>
    </Wrapper>
    <hr></hr>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      