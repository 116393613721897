export const services = [
  {
    heading: 'Water Walker',
    text: 'Das Keiper Unterwasserlaufband als Teilbereich der Physiotherapie ist eine seit vielen Jahren bewährte tierärztliche Behandlungsmethode, die klassische Therapien im Bereich der Orthopädie und Neurologie ergänzt. Das bei der Unterwassertherapie bis zu 90% verringerte Eigengewicht des Tieres reduziert die Gelenkbelastung erheblich und ermuntert den Hund zu aktiver Bewegung.',
    excerpt: 'Das Keiper Unterwasserlaufband als Teilbereich der Physiotherapie ist eine seit vielen Jahren bewährte tierärztliche Behandlungsmethode...'
  },
  {
    heading: 'Physiotherapie',
    text: 'Wir unterstützen Ihr Tier mit einer breiten Spektrum an Therapiemöglichkeiten. Akupunktur/Laserakupunktur, Magnetfeld oder manuelle Verfahren (z.B. Akussage, Massage, Triggerpunkttherapie) - wir stellen mit Ihnen für unseren Patienten die passenden und optimal effektiven Therapiepakete zusammen.',
    excerpt: 'Wir unterstützen Ihr Tier mit einer breiten Spektrum an Therapiemöglichkeiten. Akupunktur/Laserakupunktur, Magnetfeld oder manuelle Verfahren ...'
  },
  {
    heading: 'Klinische Untersuchungen',
    text: 'Wir sehen den Körper ihres Tieres und dessen Psyche als Einheit. Wir bieten eine ganzheitliche Untersuchung und effiziente Beratung. Durch die Zusammenarbeit mit einem renomierten veterinärmedizinischem Labor bieten wir auch die breite Varietät der Labordiagnostik bei Blut, Haut/Haaren, Harn und Kot. Gerne begleiten wir auch nur unterstützend die aktuelle konventionelle Behandlung ihres Lieblings in Absprache mit dem behandelnden Tierarzt.',
    excerpt: 'Wir sehen den Körper ihres Tieres und dessen Psyche als Einheit. Wir bieten eine ganzheitliche Untersuchung und effiziente Beratung...'
  },
  {
    heading: 'Alternative Heilverfahren',
    text: 'Wir bieten eine breite Palette an alternativen Heilverfahren. Homöopathie (klassisch, oder Komplexmittel), Phytotherapie, Blutegeltherapie, Neuraltherapie, Bach-Blüten-Therapie.',
    excerpt: 'Wir bieten eine breite Palette an alternativen Heilverfahren. Homöopathie (klassisch oder Komplexmittel), Phytotherapie, Blutegeltherapie,...'
  },
  {
    heading: 'Zahnsteinentfernung',
    text: 'Wir entfernen den Zahnstein bei Hund und Katze ohne Narkose, manuell oder mit Hilfe eines sanften Ultraschallscalers.',
    excerpt: 'Wir entfernen den Zahnstein bei Hund und Katze ohne Narkose, manuell oder mit Hilfe eines sanften Ultraschallscalers.'
  },
  {
    heading: 'Krallen-, Schnabel- und Ohrenpflege',
    text: 'Wir kürzen die Krallen unserer Patienten bei Bedarf durch schonendes Schleifen und vermindern so deutlich das Verletzungsrisiko des Krallenbettes. Die gleiche Technik wenden wir bei unseren Vogelpatienten an um, bei Bedarf, den Schnabel zu kürzen.',
    excerpt: 'Zu lange Krallen haben einen negativen Einfluss auf den gesamten Bewegungsapparat.'
  }
];

export const videos = [
  {
    heading: 'Perro',
    subHeading: 'Wasserspiele...',
    description: 'Perro trainiert seine Sehnen und Muskulatur im WaterWalker und hat sichtlich Spaß dabei.',
    videoLink: 'https://www.youtube-nocookie.com/embed/Ruj_iMCQ9rY'
  },
  {
    heading: 'Enie',
    subHeading: 'Genuss pur...',
    description: 'Enie kommt aufgrund ihrer stark verspannten Rückenmuskulatur zu uns und geniesst ihre Massage und das Magnetfeld. Unbedingt mit Ton auf Laut ansehen!',
    videoLink: 'https://www.youtube-nocookie.com/embed/2zMQQW9AL0E'
  },
  {
    heading: 'Harry',
    subHeading: 'Lernt wieder laufen',
    description: 'Harry im WaterWalker',
    videoLink: 'https://www.youtube-nocookie.com/embed/XmrYyg8VboY'
  },
  {
    heading: 'Pepe',
    subHeading: 'from Zero to Hero',
    description: 'Heilung bei Teckellähme. Pepe und sein Weg zurück ins Leben.',
    videoLink: 'https://www.youtube-nocookie.com/embed/xgPQISCa3gs'
  },
];